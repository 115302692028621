@font-face {
  font-family: 'VinilaBold';
  src: url('assets/fonts/Vinila Bold.otf') format('truetype');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'Vinila';
  src: url('assets/fonts/VinilaTest-Regular.otf') format('truetype');
  font-weight: normal;
  font-style: normal;

}

@import '~bootstrap-icons/font/bootstrap-icons.css';


:root {
  --primary-color: #8e00cc;
  --secondary-color: #c7ae6a;
  --accent-color: #fff;
  --color-deg: #c133ff;
  --titles: 'VinilaBold';
  --paragraph: 'Vinila';
  --button: #7c56d6;
  --brand: #7c56d6;
  --dark: #092032;
  --body: #516171;
  --border: rgba(0, 0, 0, 0.08);
  --shadow: 0px 6px 30px rgba(0, 0, 0, 0.08);
}


h1 {
  font-family: var(--titles);
  color: var(--primary-color);
}

p {
  font-family: var(--paragraph);
}

.logotipo {
  width: 100px;
}

.button-nav {
  display: inline-block;
  padding: 10px 60px;
  background-color: #293c8d;
  color: #fefdfc;
  border-radius: 0;
  border: 1px solid #1d1e31;
  text-transform: uppercase;
  border-radius: 10px;
}

.button-nav:hover {
  background-color: transparent;
  color: #1d1e31;
}

.btnAgendar {
  outline: 0;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  background: var(--button);
  min-width: 200px;
  border: 0;
  border-radius: 4px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, .1);
  box-sizing: border-box;
  padding: 16px 20px;
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1.2px;
  text-transform: uppercase;
  overflow: hidden;
  cursor: pointer;
}

.btnAgendar:hover {
  opacity: .95;
}

.btnAgendar .animation {
  border-radius: 100%;
  animation: ripple 0.6s linear infinite;
}

@keyframes ripple {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.1), 0 0 0 20px rgba(255, 255, 255, 0.1), 0 0 0 40px rgba(255, 255, 255, 0.1), 0 0 0 60px rgba(255, 255, 255, 0.1);
  }

  100% {
    box-shadow: 0 0 0 20px rgba(255, 255, 255, 0.1), 0 0 0 40px rgba(255, 255, 255, 0.1), 0 0 0 60px rgba(255, 255, 255, 0.1), 0 0 0 80px rgba(255, 255, 255, 0);
  }
}


.container-flex-header {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90vh;
  color: #3d3d3d;
  text-align: center;
}

/*header section*/
.hero_area {
  height: 100vh;

  background: linear-gradient(to right, #f2f7fd 70%, #f2f7fd 30%);
}
@media (max-width: 768px) {
  .hero_area {
    height: auto; /* Elimina el 100vh en móviles */
    background: linear-gradient(to right, #f2f7fd 100%); /* Ajusta el fondo si es necesario */
  }
}

.sub_page .hero_area {
  height: auto;
}

.header_section .container-fluid {
  padding-right: 25px;
  padding-left: 25px;
}

.header_section .nav_container {
  margin: 0 auto;
}

.custom_nav-container.navbar-expand-lg .navbar-nav .nav-link img {
  width: 22px;
  margin-right: 15px;
}

.custom_nav-container.navbar-expand-lg .navbar-nav .nav-link {
  padding: 0px 25px;
  color: #fefeff;
  text-align: center;
  font-family: "Roboto", sans-serif;
}

.custom_menu-btn {
  z-index: 9;
  position: absolute;
  right: 15px;
  top: 14px;
}

.custom_menu-btn button {
  margin-top: 12px;
  outline: none;
  border: none;
  background-color: transparent;
}

.custom_menu-btn span {
  display: block;
  width: 35px;
  height: 5px;
  background-color: #fff;
  margin: 7px 0;
  -webkit-transition: all .3s;
  transition: all .3s;
}

.custom_menu-btn .custom_menu-btn .s-2 {
  -webkit-transition: all .1s;
  transition: all .1s;
}

.menu_btn-style button {
  position: fixed;
  right: 29px;
  top: 14px;
}

.menu_btn-style button .s-1 {
  -webkit-transform: rotate(45deg) translateY(17px);
  transform: rotate(45deg) translateY(17px);
}

.menu_btn-style button .s-2 {
  -webkit-transform: translateX(100px);
  transform: translateX(100px);
}

.menu_btn-style button .s-3 {
  -webkit-transform: rotate(-45deg) translateY(-17px);
  transform: rotate(-45deg) translateY(-17px);
}

.overlay {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: black;
  background-color: rgba(0, 0, 0, 0.9);
  overflow-x: hidden;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.overlay .closebtn {
  position: absolute;
  top: 0;
  right: 30px;
  font-size: 60px;
}

.overlay a {
  padding: 0px;
  text-decoration: none;
  font-size: 22px;
  color: #f1f1f1;
  display: block;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.overlay-content {
  position: relative;
  top: 25%;
  width: 100%;
  text-align: center;
  margin-top: 30px;
}

.menu_width {
  width: 100%;
}

a,
a:hover,
a:focus {
  text-decoration: none;
}

a:hover,
a:focus {
  color: initial;
}

.btn,
.btn:focus {
  outline: none !important;
  -webkit-box-shadow: none;
  box-shadow: none;
}


/* SECTION HEADER NAV*/

.layout_margin {
  margin: 90px 0;
}

.layout_padding {
  padding: 90px 0;
}

.layout_padding2 {
  padding: 45px 0;
}

.layout_padding2-top {
  padding-top: 45px;
  padding-bottom: 40px;
}

.layout_padding2-bottom {
  padding-bottom: 10px;
}

.layout_padding-top {
  padding-top: 80px;
}

.layout_padding-bottom {
  padding-bottom: 90px;
}

.heading_container h2 {
  font-weight: bold;
  color: #1d1e31;
  position: relative;
  padding-bottom: 10px;
}

.heading_container .about {
  font-weight: bold;
  color: #ffffff;
  text-align: center;
  position: relative;
  padding-bottom: 10px;
}

.heading_container h2::before {
  content: "";
  width: 100px;
  height: 1px;
  background-color: var(--primary-color);
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: 0;
}

.hero_next_section-margin {
  margin-top: 100px;
}

.custom_nav-container .nav_search-btn {
  /* background-image: url(../images/search-icon.png);*/
  background-size: 18px;
  background-repeat: no-repeat;
  width: 35px;
  height: 35px;
  padding: 0;
  border: none;
  margin: 0 40px 0 15px;
  background-position: center;
}

.navbar-brand {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.navbar-brand img {
  width: 40px;
  margin-right: 5px;
}

.navbar-brand span {
  text-transform: uppercase;
  font-size: 24px;
  font-weight: 700;
  color: #8547ca;
  margin-top: 5px;
}

.custom_nav-container {
  z-index: 99999;
  padding: 15px 0;
}

.custom_nav-container .navbar-toggler {
  outline: none;
}

.custom_nav-container .navbar-toggler .navbar-toggler-icon {
  /* background-image: url(../images/menu.png);*/
  background-size: 55px;
}

.quote_btn-container a {
  display: inline-block;
  padding: 5px 15px;
  background-color: #fc5d35;
  color: #f7f7f7;
  font-size: 14px;
  text-transform: uppercase;
}


.slider_section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: calc(100% - 75px);
  padding: 50px 0;
}

.slider_section .row {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: unset;
}

.slider_section .row .col-md-7,
.slider_section .row .col-md-5 {
  position: unset;
}

.slider_section .carousel {
  position: unset;
}

.slider_section .detail-box h2 {
  text-transform: uppercase;
  font-weight: normal;
  font-size: 30px;
}

.slider_section .detail-box h1 {
  text-transform: uppercase;
  font-weight: bold;
  color: #8547ca;
  font-size: 50px;
}

.slider_section .detail-box a {
  display: inline-block;
  padding: 10px 60px;
  background-color: #293c8d;
  color: #fefdfc;
  border-radius: 0;
  border: 1px solid #1d1e31;
  text-transform: uppercase;
  margin-top: 35px;
  border-radius: 10px;
}

.slider_section .detail-box a:hover {
  background-color: transparent;
  color: #1d1e31;
}

.slider_section .img-box img {
  height: 100%;
}

.slider_section .carousel-control-prev,
.slider_section .carousel-control-next {
  width: 60px;
  height: 60px;
  border-radius: 100%;
  left: 70%;
  opacity: 1;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  background-repeat: no-repeat;
}

.slider_section .carousel-control-prev {
  background-color: #1d1e31;
  top: 80px;
  /*background-image: url(../images/left-arrow.png);*/
  background-position: 14px center;
}

.slider_section .carousel-control-next {
  background-color: #f2f7fd;
  top: initial;
  bottom: 150px;
  /*background-image: url(../images/right-arrow.png);*/
  background-position: 35px center;
}

.slider_section .carousel-indicators {
  margin: 0;
  bottom: 50%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  left: 25px;
  -webkit-transform: translateY(50%);
  transform: translateY(50%);
}

.slider_section .carousel-indicators li {
  margin: 0;
  width: 30px;
  height: 30px;
  background-color: #1d1e31;
  opacity: 1;
}

.slider_section .carousel-indicators li.active {
  background-color: #8547ca;
}



.feature_section .box {
  background-color: #fcfcfd;
  margin: auto;
  height: 200px;
  width: 100%;
  padding: 15px;
}

.feature_section .box .head-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.feature_section .box .head-box .img-box {
  margin-right: 15px;
}

.feature_section .box .head-box .img-box svg {
  width: 50px;
  height: auto;
  fill: #8547ca;
}

.feature_section .box .head-box h6 {
  font-weight: bold;
  font-size: 18px;
}

.feature_section .box .detail-box {
  margin-top: 15px;
}

.feature_section .box .detail-box p {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
}

.feature_section .box:hover {
  background-color: #8547ca;
  color: #fefdfc;
}

.feature_section .box:hover .head-box .img-box svg {
  fill: #fefdfc;
}

.feature_section a {
  display: inline-block;
  padding: 10px 40px;
  background-color: #8c60bd;
  color: #fefdfc;
  border-radius: 0;
  border: 1px solid #8c60bd;
  border-radius: 10px;
}

.feature_section a:hover {
  background-color: transparent;
  color: #8c60bd;
}

.download_section {
  font-family: 'Poppins', sans-serif;
}

.about_section .box {
  background-color: #333456;
  padding: 15px;
  margin: 45px 0;
  color: #fefdfc;
  height: 200px;
  border-radius: 10px;
}


.about_section .box .head-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.about_section .box .head-box .img-box {
  margin-right: 15px;
  width: 60px;
  height: 60px;
  border: 1px solid #fefdfc;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 50%;
}

.about_section .box .head-box .img-box img {
  width: 30px;
}

.about_section .box .head-box h6 {
  font-weight: bold;
  font-size: 18px;
}

.about_section .box .detail-box {
  margin-top: 15px;
}

.about_section .box .detail-box p {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
}

.about_section .btn-1 {
  display: inline-block;
  padding: 10px 40px;
  background-color: #8c60bd;
  color: #fefdfc;
  border-radius: 0;
  border: 1px solid #8c60bd;
  margin-top: 25px;
}

.about_section .btn-1:hover {
  background-color: transparent;
  color: #8c60bd;
}

.about_section .btn-2 {
  display: inline-block;
  padding: 10px 40px;
  background-color: #323559;
  color: #fefdfc;
  border-radius: 0;
  border: 1px solid #323559;
  margin-top: 25px;
}

.about_section .btn-2:hover {
  background-color: transparent;
  color: #323559;
}

.about_section .main-img-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.about_section .main-img-box img {
  width: 75%;
  -webkit-filter: drop-shadow(0px 0px 30px rgba(0, 0, 0, 0.37));
  filter: drop-shadow(0px 0px 30px rgba(0, 0, 0, 0.37));
}

.about_section {
  /* background-image: url(../images/about-bg.jpg);*/
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
}

.about_section .row {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.about_section .detail-box {
  height: 550px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.about_section .detail-box.b-1 {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.about_section .detail-box.b-1 a {
  display: inline-block;
  padding: 10px 40px;
  background-color: #8c60bd;
  color: #fefdfc;
  border-radius: 0;
  border: 1px solid #8c60bd;
}

.about_section .detail-box.b-1 a:hover {
  background-color: transparent;
  color: #8c60bd;
}

.subscribe_section {
  background-color: #f2f7fd;
}

.subscribe_section form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.subscribe_section form input {
  width: 69%;
  height: 50px;
  border: none;
  padding-left: 15px;
  -webkit-box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.12);
}

.subscribe_section form button {
  width: 29%;
  height: 50px;
  text-transform: uppercase;
  display: inline-block;
  padding: 0 0;
  background-color: #8c60bd;
  color: #fefdfc;
  border-radius: 0;
  border: 1px solid #8c60bd;
  font-weight: bold;
  line-height: 50px;
}

.subscribe_section form button:hover {
  background-color: transparent;
  color: #8c60bd;
}

.client_section .client_container .client-id {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.client_section .client_container .client-id .img-box {
  width: 75px;
  margin-right: 15px;
}

.client_section .client_container .client-id .img-box img {
  width: 100%;
}

.client_section .client_container .client-id .name img {
  width: 35px;
}

.client_section .client_container .client-id .name h6 {
  color: #8547ca;
  margin: 5px 0;
  font-weight: bold;
}

.client_section .client_container .client-detail {
  margin-top: 20px;
}

.client_section .client_container a {
  display: inline-block;
  padding: 10px 40px;
  background-color: #8c60bd;
  color: #fefdfc;
  border-radius: 0;
  border: 1px solid #8c60bd;
}

.client_section .client_container a:hover {
  background-color: transparent;
  color: #8c60bd;
}

/* contact section */
.contact_section {
  background-color: #f2f7fd;
}

.contact_section h2 {
  font-size: 26px;
  color: #010103;
  font-weight: 600;
  font-family: 'Raleway', sans-serif;
  margin-bottom: 35px;
}

.contact_section .row {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.contact_section input {
  border: none;
  outline: none;
  border-bottom: 1px solid #000;
  width: 90%;
  margin: 15px 0;
  background-color: transparent;
}

.contact_section input::-webkit-input-placeholder {
  color: #595959;
  font-size: 14px;
}

.contact_section input:-ms-input-placeholder {
  color: #595959;
  font-size: 14px;
}

.contact_section input::-ms-input-placeholder {
  color: #595959;
  font-size: 14px;
}

.contact_section input::placeholder {
  color: #595959;
  font-size: 14px;
}

.contact_form-container button {
  border: none;
  background-color: #8c60bd;
  color: #fff;
  font-size: 15px;
  padding: 15px 45px;
  border-radius: 30px;
  text-transform: uppercase;
  font-weight: bold;
}

.contact_img-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.contact_img-box img {
  width: 100%;
}

/* end contact section */
.transparent-rounded-background {
  background-color: rgba(0, 0, 0, 0.3);
  /* Fondo oscuro con transparencia */
  border-radius: 15px;
  /* Bordes redondeados */
  padding: 15px;
  /* Espaciado interno */
}

/* info section */
.info_section {
  background-color: #1d1e31;
  color: #ffffff;
  font-family: 'Poppins', sans-serif;
}

.info_section h5 {
  margin-bottom: 12px;
  font-size: 22px;
}

.info_logo-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.info_logo-box h2 {
  font-weight: bold;
  padding: 5px 35px;
  border-bottom: 1.5px solid #8547ca;
}

.info_section ul {
  padding: 0;
}

.info_section ul li {
  list-style-type: none;
  margin: 3px 0;
}

.info_section ul li a {
  color: #ffffff;
}

.info_section ul li a:hover {
  color: #8547ca;
}

.info_section .form_container input {
  width: 225px;
  height: 40px;
  padding: 10px;
}

.info_section .form_container::-webkit-input-placeholder {
  color: #ccc8c8;
}

.info_section .form_container:-ms-input-placeholder {
  color: #ccc8c8;
}

.info_section .form_container::-ms-input-placeholder {
  color: #ccc8c8;
}

.info_section .form_container::placeholder {
  color: #ccc8c8;
}

.info_section .form_container button {
  background-color: #8c60bd;
  border: none;
  outline: none;
  color: #fff;
  padding: 8px 30px;
  margin-top: 15px;
  font-size: 15px;
  text-transform: uppercase;
}

.social_container {
  width: 95%;
  margin: 0 auto;
  border-top: 1px solid #898989;
  padding: 25px 0;
}

.info_section .social-box {
  margin: 0 auto;
  width: 400px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.info_section .social-box img {
  width: 35px;
  margin-right: 5px;
}

/* end info section */
/* footer section*/
.footer_section {
  background-color: #8547ca;
  padding: 20px;
}

.footer_section p {
  margin: 0;
  text-align: center;
  color: #fefdfc;
}

.footer_section a {
  color: #fefdfc;
}

/*# sourceMappingURL=style.css.map */

/* CARD SERVICES*/

.card-container {
  width: 100%;
  height: 300px;
  position: relative;
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  overflow: hidden;
}

.card {
  width: 100%;
  height: 100%;
  border-radius: inherit;
}

.card .front-content {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1)
}

.card .front-content p {
  font-size: 32px;
  font-weight: 700;
  opacity: 1;
  background: linear-gradient(-45deg, var(--color-deg) 0%, var(--primary-color) 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1)
}

.icon-container {
  position: absolute;
  /* Posiciona el ícono en relación al contenedor frontal */
  top: 20%;
  /* Ajusta la distancia desde la parte superior según sea necesario */
  left: 50%;
  transform: translateX(-50%);
  /* Centra el ícono horizontalmente */
}

.icon-container i {
  font-size: 40px;
  /* Ajusta el tamaño del ícono según sea necesario */
  color: var(--secondary-color);
  /* Ajusta el color del ícono según sea necesario */
}

.card .content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 10px;
  background: linear-gradient(-45deg, var(--color-deg) 0%, var(--primary-color) 100%);
  color: #e8e8e8;
  padding: 20px;
  line-height: 1.5;
  border-radius: 5px;
  pointer-events: none;
  transform: translateX(-96%);
  transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1);
}

.card .content .heading {
  font-size: 32px;
  font-weight: 700;
}

.card:hover .content {
  transform: translateY(0);
}

.card:hover .front-content {
  transform: translateX(-30%);
}

.card:hover .front-content p {
  opacity: 0;
}

.section-benefits {

  background: linear-gradient(to bottom, #c87ee8 70%, #ffffff);
  display: flex;
  color: white;
  text-align: center;
}

.highlight {
  font-size: large;

}



.floating-image {
  position: relative;

  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  filter: drop-shadow(0px 20px 40px rgba(0, 0, 0, 0.3));
}

.floating-image::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* Reemplaza con la ruta de tu imagen */
  filter: drop-shadow(0px 10px 30px rgba(0, 0, 0, 0.2));
  z-index: -1;
  /* Coloca la sombra detrás de la imagen */
}

.floating-image:hover {
  transform: translateY(-10px);

}

/*e card*/

/* From Uiverse.io by MikeAndrewDesigner */
.e-card {
  background: transparent;
  box-shadow: 0px 8px 28px -9px rgba(0, 0, 0, 0.45);
  position: relative;
  width: 240px;
  height: 350px;
  border-radius: 16px;
  overflow: hidden;

}



.wave {
  position: absolute;
  width: 540px;
  height: 700px;
  opacity: 0.6;
  left: 0;
  top: 0;
  margin-left: -50%;
  margin-top: -70%;
  background: linear-gradient(744deg, var(--primary-color), var(--color-deg) 60%, #00ddeb);
}

.icon {
  width: 3em;
  margin-top: -1em;
  padding-bottom: 1em;
}

.infotop {
  text-align: center;
  font-size: 20px;
  position: absolute;
  top: 5.6em;
  left: 0;
  right: 0;
  color: rgb(255, 255, 255);
  font-weight: 600;
  top: 0;
  /* Asegura que la imagen y descripción estén en la parte superior */
}

.name {
  font-size: 14px;
  position: relative;
  text-transform: lowercase;
  margin-bottom: 2px;
  padding: 10px 10px;
}

.wave:nth-child(2),
.wave:nth-child(3) {
  top: 210px;
}

.playing .wave {
  border-radius: 40%;
  animation: wave 3000ms infinite linear;
}

.wave {
  border-radius: 40%;
  animation: wave 55s infinite linear;
}

.playing .wave:nth-child(2) {
  animation-duration: 4000ms;
}

.wave:nth-child(2) {
  animation-duration: 50s;
}

.playing .wave:nth-child(3) {
  animation-duration: 5000ms;
}


.wave:nth-child(3) {
  animation-duration: 45s;
}

@keyframes wave {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*Footer*/


.socialContainer {
  width: 52px;
  height: 52px;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  transition-duration: .3s;
}

/* instagram*/
.containerOne:hover {
  background-color: #0015ff;
  transition-duration: .3s;
}

/* twitter*/
.containerTwo:hover {
  background-color: #00acee;
  transition-duration: .3s;
}

/* linkdin*/
.containerThree:hover {
  background-color: #ff2c2c;
  transition-duration: .3s;
}

/* Whatsapp*/
.containerFour:hover {
  background-color: #d62976;
  transition-duration: .3s;
}

.containerFive:hover {
  background-color: #636363;
  transition-duration: .3s;
}

.socialContainer:active {
  transform: scale(0.9);
  transition-duration: .3s;
}

/*BLOG*/

.blog-post {
  position: relative;
  background-color: #fff;
  box-shadow: var(--shadow);
}

.blog-post .content {
  padding: 32px;
}

.blog-post a {
  position: absolute;
  top: 20px;
  left: 20px;
  background-color: var(--brand);
  padding: 2px 12px;
  border-radius: 100px;
  text-decoration: none;
  color: #fff;
}

.blog-post h5 {
  margin-top: 12px;
  margin-bottom: 12px;
}

.blog-post small {
  text-transform: uppercase;
  color: var(--brand);
  text-decoration: underline;
}

/*card socios*/

.cards_socios {
  display: flex;
  gap: 15px;
}

.cards_socios .red {
  background-color: #f2f7fd;
  padding: 5px;
  box-shadow: #000 10px;
}

.cards_socios .blue {
  background-color: #f2f7fd;
  padding: 40px;
}

.cards_socios .green {
  background-color: #f2f7fd;
  padding: 15px;
}

.cards_socios .four {
  background-color: #f2f7fd;

}

.cards_socios .card {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  height: 100px;

  border-radius: 10px;
  color: white;
  cursor: pointer;
  transition: 400ms;
}

.cards_socios .card p.tip {
  font-size: 1em;
  font-weight: 700;
}

.cards_socios .card p.second-text {
  font-size: .7em;
}

.cards_socios .card:hover {
  transform: scale(1.1, 1.1);
}

.cards_socios:hover>.card:not(:hover) {
  filter: blur(10px);
  transform: scale(0.9, 0.9);
}
.info_section .social-icons a {
  width: 50px;
  height: 50px;
  font-size: 20px;
  margin-left: 4px;
  margin-right: 4px;
}
.social-icons a {
  width: 28px;
  height: 28px;
  display: inline-flex;
  color: #fff;
  background-color: rgba(255, 255, 255, 0.25);
  text-decoration: none;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
}

.social-icons a:hover {
  background-color: #fff;
  color: var(--brand);
}
.footer-bottom {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  padding-bottom: 20px;
  padding-top: 20px;
}
.service {
  padding: 32px;
  background-color: #fff;
  box-shadow: var(--shadow);
}

.service h5 {
  margin-top: 24px;
  margin-bottom: 14px;
}

.service img {
  width: 90px;
}
.service p{
  color:#727272
}
.btn-services{
  border-left: 5px solid red; /* Línea roja a la izquierda */
  padding: 20px;              /* Espaciado interno */
  background-color: white;     /* Fondo blanco */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Sombra suave */
  max-width: 400px;            /* Ancho máximo de la tarjeta */
  margin: 20px auto;           /* Centrar la tarjeta horizontalmente */
  border-radius: 8px;          /* Bordes redondeados */
}

.card h2 {
  color: #f6b60d; /* Color amarillo (puedes ajustarlo para que coincida con tu diseño) */
  font-size: 24px;
  margin-bottom: 10px;
}

.card p {
  color: #666; /* Color gris para el texto */
  font-size: 16px;
  margin: 0;   /* Elimina margen adicional en el párrafo */
}

/*CARD COMO*/
.card-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 250px;
  background-color: #f5f5f5;
  padding: 30px;
  transition: all 0.3s ease;
  transform: scale(1); /* Valor inicial de escala */
}

.card-como {
  position: relative;
  height: 200px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 70px;
  transition: all 0.3s ease;
}

.card-como:hover {
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
}
.card-container:hover {
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
  transform: scale(1.05); /* Aumenta el tamaño un 5% al hacer hover */
}


.card-icon {
  color: #8e00cc; /* Color del ícono */
  margin-bottom: 15px;
}

.icon {
  width: 40px;
  height: 40px;
}

.card-content h2 {
  font-size: 20px;
  font-weight: bold;
  margin: 0;
  margin-bottom: 10px;
}

.card-content p {
  color: #666;
  font-size: 14px;
  margin: 0;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.card-number {
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: #8e00cc;
  color: white;
  padding: 10px;
  border-radius: 50%;
  font-weight: bold;
  font-size: 25px;
}



/*card blog*/
.card-container-blog {
  width: 100%;
  max-width: 400px;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: white;
}

.card-container-blog .card-image img {
  width: 100%;
  height: auto;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.card-container-blog .card-content {
  padding: 15px;
}

.card-container-blog .card-tag-and-time {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.card-container-blog .card-tag {
  font-size: 0.9rem;
  font-weight: bold;
  color: #6e46ce;
  text-transform: uppercase;
}

.card-container-blog .card-read-time {
  font-size: 0.9rem;
  color: gray;
}

.card-container-blog .card-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin: 8px 0;
}

.card-container-blog .card-author {
  display: flex;
  align-items: center;
  margin-top: 15px;
}

.card-container-blog .card-author-img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
}

.card-container-blog .card-author-name {
  font-size: 1rem;
  font-weight: bold;
  margin: 0;
}

.card-date {
  font-size: 0.9rem;
  color: gray;
  margin: 0;
}

